import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../component/shared/footer";
import Loading from "../../component/shared/loading/Loading";
import NavBar from "../../component/shared/navbar";
import useFetch from "../../hooks/useFetch";
import useSession from "../../hooks/useSession";
import FileView from "./fileView";
import Gallery from "./gallery";
import HeadInformation from "./headInformation";
import PersonalDetail from "./personalDetail";
import PersonalInformation from "./personalInformation";
import WorkDetails from "./workDetails";
import WorkExperience from "./WorkExperience";
import PassportDetails from "./passportStatus";
import EmploymentStatus from "./EmploymentStatus";
import EmploymentDetails from "./EmploymentDetails";

const IndividualPerson = () => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );
  const [notViewer, setNotViewer] = useState();
  const { id } = useParams();

  const [employee, , employeeLoading] = useFetch("form", id);

  const [user, userLoading] = useSession();

  useEffect(() => {
    if (user?.role) {
      setNotViewer(
        user?.role?.toLowerCase() === "admin" ||
          user?.role?.toLowerCase() === "moderator"
      );
    }
  }, [user]);

  if (userLoading || employeeLoading) {
    return <Loading />;
  }

  const {
    photo,
    remarks,
    addLinks,
    media,
    files,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    experience,
    experienceDetails,
    employment,
    employmentDetails,
    gender,
    passportStat,
  } = employee;
  return (
    <main>
      <NavBar user={user} darkMode={darkMode} setDarkMode={setDarkMode} />
      <div className="flex items-center justify-center">
        <div className="dark:bg-[#292929] text-[#000000b3] w-full max-w-7xl">
          <div className="container mx-auto pt-6 px-4 lg:px-10">
            <div className="grid items-stretch grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 lg:gap-4">
              <div className="w-full flex justify-center md:justify-start">
                <img
                  src={
                    photo
                      ? photo
                      : gender === "male"
                      ? "https://i.ibb.co/c8bxd3G/male.webp"
                      : gender === "female"
                      ? "https://i.ibb.co/z2wyPT2/female.webp"
                      : "https://i.ibb.co/LQBXSsr/Placeholder.png"
                  }
                  alt=""
                  className="max-w-[16rem] lg:max-w-none object-cover aspect-square rounded-3xl shadow-lg dark:shadow-gray-700 w-[100%]"
                />
              </div>
              <div className="lg:col-span-2 px-0 lg:p-6 lg:px-10 lg:py-2 lg:-ml-[1rem]">
                <HeadInformation employee={employee} notViewer={notViewer} />
              </div>

              {notViewer && (
                <div className="flex flex-col justify-center i p-6 shadow dark:shadow-gray-700 rounded-xl dark:text-white">
                  <div className="grid grid-cols-2 w-full pb-2">
                    <h2 className="text-start font-semibold">Created By: </h2>
                    <h2 className="break-words">{createdBy.split("@")[0]}</h2>
                  </div>
                  <div className="grid grid-cols-2 pb-2">
                    <h2 className="text-start font-semibold">Created at: </h2>
                    <h2 className="break-words">
                      {moment(createdAt).format("DD-MM-YYYY")}
                    </h2>
                  </div>

                  {updatedBy && (
                    <div className="grid grid-cols-2 w-full pb-2">
                      <h2 className="text-start font-semibold">Updated By: </h2>
                      <h2 className="break-words">{updatedBy.split("@")[0]}</h2>
                    </div>
                  )}
                  {updatedBy && (
                    <div className="grid grid-cols-2 w-full ">
                      <h2 className="text-start font-semibold">Updated at: </h2>
                      <h2>{moment(updatedAt).format("DD-MM-YYYY")}</h2>
                    </div>
                  )}
                </div>
              )}
            </div>

            <hr className="mt-10 border-gray-300 border-[1.5px]" />

            <div className="lg:w-full mx-auto py-10">
              <div className="grid lg:grid-cols-2 gap-4 dark:text-white">
                <PersonalInformation
                  employee={employee}
                  notViewer={notViewer}
                />

                <PersonalDetail employee={employee} />

                <WorkDetails employee={employee} notViewer={notViewer} />

                {/* passport status */}
                {passportStat && (
                  <PassportDetails employee={employee} notViewer={notViewer} />
                )}

                {remarks && (
                  <div className=" text-base lg:text-lg shadow-lg p-6 rounded-2xl dark:shadow-gray-700">
                    <h2 className="text-2xl font-bold pb-4 ">Remarks: </h2>
                    <div className="grid pb-2 break-words overflow-hidden inline-block">
                      <p className="break-words inline-block max-w-md">
                        {remarks}
                      </p>
                    </div>
                  </div>
                )}
                {addLinks && (
                  <div className=" text-base lg:text-lg shadow-lg p-6 rounded-2xl dark:shadow-gray-700">
                    <h2 className="text-2xl font-bold  pb-4 ">
                      Additional Links:{" "}
                    </h2>
                    <div className="grid pb-2 px-5 lg:px-0">
                      <h2 className="text-justify break-words">
                        <ul className="list-decimal lg:pl-8 text-blue-500">
                          {addLinks.split(",").map((link, index) => (
                            <li key={index}>
                              <a target="_blank" rel="noreferrer" href={link}>
                                {link}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </h2>
                    </div>
                  </div>
                )}
              </div>
              <hr className="mt-10 border-gray-300 border-[1.5px]" />
            </div>

            {employment && employment !== "Please select an option" && (
              <>
                {/* Employment status */}
                <EmploymentStatus employee={employee} notViewer={notViewer} />
              </>
            )}

            {employment === "Employed" && employmentDetails.length > 0 && (
              <>
                <div className="pt-5 pb-10">
                  <h2 className="text-2xl font-bold pb-4 px-4 dark:text-white">
                    Employment Details
                  </h2>
                  <EmploymentDetails employmentDetails={employmentDetails} />
                </div>
              </>
            )}
            {/* {user?.role?.toLowerCase() !== "client" ? (
              <> */}
            {(notViewer || user?.role?.toLowerCase() === "client-admin") &&
              media.length > 0 && (
                <>
                  {notViewer && (
                    <hr className=" border-gray-300 border-[1.5px] mb-10" />
                  )}
                  <Gallery media={media} />
                </>
              )}

            {experience === "Yes" && experienceDetails.length > 0 && (
              <>
                <div className="border-gray-300 border-[1.5px]" />
                <div className="pt-8 pb-10">
                  <h2 className="text-2xl font-bold pb-4 px-4 dark:text-white">
                    Work Experience
                  </h2>
                  <WorkExperience experienceDetails={experienceDetails} />
                </div>
              </>
            )}

            {files.length > 0 && (
              <>
                <div className="border-gray-300 border-[1.5px]" />
                <div className="pt-8 pb-10">
                  <h2 className="text-2xl font-bold pb-4 px-4 dark:text-white">
                    Files
                  </h2>
                  <FileView files={files} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Footer darkTheme={darkMode} />
    </main>
  );
};

export default IndividualPerson;
