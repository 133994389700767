import React, { useRef, useState } from "react";
import {
  formBodyCX,
  formTitleCX,
  modalCloseButtonCX,
  modalCX,
  modalToggleCX,
} from "./styledClass";

const FilterModal = ({ filter, setFilter }) => {
  const formRef = useRef();
  const [filtersDef, setFiltersDef] = useState([
    {
      id: "employed",
      name: "Employment Status",
      options: [
        { value: "Employed", label: "Employed", checked: false },
        { value: "On Process", label: "On Process", checked: false },
        { value: "Rejected", label: "Rejected", checked: false },
        { value: "Waiting List", label: "Waiting List", checked: false },
        { value: "Cancelled", label: "Cancelled", checked: false },
      ],
    },
    {
      id: "passport",
      name: "Passport Status",
      options: [
        { value: "Submitted", label: "Submitted", checked: false },
        { value: "Not Submitted", label: "Not Submitted", checked: false },
        { value: "Returned", label: "Returned", checked: false },
      ],
    },
  ]);

  const checkBoxEvent = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      if (!filter.includes(value)) {
        setFilter([...filter, value]);
      }
    } else {
      const arr = filter.filter((item) => {
        return item !== value;
      });
      setFilter(arr);
    }
  };

  return (
    <div>
      <input type="checkbox" id="filterEmployee" className={modalToggleCX} />
      <div className={modalCX}>
        <div className="modal-box max-w-md dark:bg-[#2C2E33]">
          <label
            htmlFor="filterEmployee"
            onClick={() => {
              formRef.current.reset();
            }}
            className={modalCloseButtonCX}
          >
            ✕
          </label>
          <form ref={formRef} className={formBodyCX}>
            <h2 className={formTitleCX}>Filter Employee</h2>
            <div className="lg:flex mx-auto justify-between">
              {filtersDef.map((section) => (
                <div
                  key={section.id}
                  className="border-t border-gray-200 px-4 py-6"
                >
                  <h3 className="-mx-2 -my-3 flow-root">
                    <span className="font-medium text-gray-900 dark:text-white">
                      {section.name}
                    </span>
                  </h3>
                  <div className="pt-6">
                    <div className="space-y-6">
                      {section.options.map((option, optionIdx) => (
                        <div key={option.value} className="flex items-center">
                          <input
                            id={`filter-mobile-${section.id}-${optionIdx}`}
                            name={`${section.id}[]`}
                            defaultValue={option.value}
                            type="checkbox"
                            defaultChecked={
                              filter.length
                                ? filter.includes(option.value)
                                : option.checked
                            }
                            onClick={checkBoxEvent}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                            className="ml-3 min-w-0 flex-1 text-gray-500"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className=" flex justify-center ">
              <label
                htmlFor="filterEmployee"
                onClick={(e) => {
                  formRef.current.reset();
                }}
                className="p-5 mx-auto bg-green-300 rounded-lg hover:bg-green-400 cursor-pointer transition duration-700 ease-in-out font-medium text-gray-900"
              >
                Filter Data
              </label>
              <label
                htmlFor="filterEmployee"
                onClick={(e) => {
                  setFilter([]);
                  formRef.current.reset();
                }}
                className="p-5 mx-auto bg-gray-200 rounded-lg hover:bg-gray-300 cursor-pointer transition duration-700 ease-in-out font-medium text-gray-900"
              >
                Clear Filter
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
