import moment from "moment";
import React, { useState } from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";
import DatePic from "../datePicker";

const PassportInformation = ({ uniqueEmployee }) => {
  const { passportStat, SubmittedTo, SubmitDate, returnDate, returnedTo } =
    uniqueEmployee;
  const [passStat, setPassStat] = useState(
    passportStat?.split(",")[0] ? passportStat?.split(",")[0] : "Not Submitted"
  );
  const handleClearance = (event) => {
    setPassStat(event.target.value.split(",")[0]);
  };
  return (
    <div>
      <div className={sectionGridCX}>
        <CustomInputField
          label={"Status"}
          type={"text"}
          name={"passportStat"}
          select={true}
          options={[
            ["Not Submitted", "Not Submitted"],
            ["Submitted", "Submitted"],
            ["Returned", "Returned"],
          ]}
          handleInput={handleClearance}
          defaultValue={passportStat}
        />

        {passStat === "Submitted" && (
          <>
            {/*  <CustomInputField
              label={"Submission Date"}
              type={"text"}
              name={"SubmitDate"}
              defaultValue={SubmitDate}
            /> */}
            <DatePic
              label={"Submission Date"}
              name={"SubmitDate"}
              defaultValue={SubmitDate}
            />

            <CustomInputField
              label={"Submitted By"}
              type={"text"}
              name={"SubmittedTo"}
              defaultValue={SubmittedTo}
            />
          </>
        )}
        {passStat === "Returned" && (
          <>
            {/* <CustomInputField
              label={"Return Date"}
              type={"text"}
              name={"returnDate"}
              defaultValue={returnDate}
            /> */}
            <DatePic
              label={"Return Date"}
              name={"returnDate"}
              defaultValue={returnDate}
            />

            <CustomInputField
              label={"Returned To"}
              type={"text"}
              name={"returnedTo"}
              defaultValue={returnedTo}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default PassportInformation;
