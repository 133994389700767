import React from "react";
import { deleteData } from "../../hooks/delete";
import {
  actionBoxCX,
  iconBodyCX,
  iconBoxCX,
  iconCX,
  inputToggleCX,
  modalCX,
  noButtonCX,
  subTitleCX,
  titleCX,
  yesButtonCX,
} from "./styledClass";

const CustomAlert = ({ endPoint, deleteID, userName }) => {
  const handleDelete = () => {
    deleteData(`${endPoint}/${deleteID}`);
  };

  return (
    <div>
      <input type="checkbox" id="delete-modal" className={inputToggleCX} />
      <div className={modalCX}>
        <div className={iconBodyCX}>
          <h1 className={iconBoxCX}>
            <i className={iconCX}></i>
          </h1>
          <h3 className={titleCX}>Do you want to delete? </h3>
          <h3 className={`${titleCX} text-red-400`}>{userName}</h3>
          <p className={subTitleCX}>Note: You won't be able to revert this!</p>
          <div className={actionBoxCX}>
            <label
              onClick={handleDelete}
              htmlFor="delete-modal"
              className={yesButtonCX}
            >
              Yes
            </label>
            <label htmlFor="delete-modal" className={noButtonCX}>
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;
