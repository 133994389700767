import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";
import DatePic from "../datePicker";

const WorkInformation = ({ clearance, setClearance }) => {
  const handleClearance = (event) => {
    setClearance(event.target.value.split(",")[0]);
  };
  return (
    <div>
      <div className={sectionGridCX}>
        <CustomInputField
          label={"Work Category"}
          type={"text"}
          name={"workCategory"}
          select={true}
          options={[
            ["CARP", "Carpenter"],
            ["CH", "Chef"],
            ["CE", "Civil Engineer"],
            ["EE", "Electrical Engineer"],
            ["ELEC", "Electrician"],
            ["GW", "General Worker"],
            ["GR", "Grinder"],
            ["HRC", "Horeca"],
            ["HVAC", "HVAC Technician"],
            ["MAS", "Mason"],
            ["MAN", "Manager"],
            ["ME", "Mechanical Engineer"],
            ["MW", "Millwright"],
            ["PF", "Pipe Fitter"],
            ["SF", "Steel Fixer"],
            ["WP", "Welder-Plate"],
            ["WS", "Welder-SMAW"],
            ["WT", "Welder-TIG"],
          ]}
          required={true}
        />
        <CustomInputField
          label={"Level"}
          type={"text"}
          name={"levels"}
          select={true}
          options={[
            ["Level 1", "Level 1"],
            ["Level 2", "Level 2"],
            ["Level 3", "Level 3"],
            ["Level 4", "Level 4"],
          ]}
        />
        <CustomInputField
          label={"Agent Name"}
          type={"text"}
          name={"agentName"}
        />

        <CustomInputField
          label={"Police Clearance"}
          type={"text"}
          name={"policeClearance"}
          select={true}
          options={[
            ["No", "No"],
            ["Yes", "Yes"],
          ]}
          handleInput={handleClearance}
        />

        {clearance === "Yes" && (
          <>
            <CustomInputField
              label={"Police Clearance Number"}
              type={"text"}
              name={"policeClearanceNo"}
              required={true}
              uppercase={true}
            />

            {/* <CustomInputField
              label={"Police Clearance Date"}
              type={"date"}
              name={"policeClearanceIsDate"}
              required={true}
            /> */}
            <DatePic
              label={"Police Clearance Date"}
              name={"policeClearanceIsDate"}
              required={true}
            />
          </>
        )}

        <CustomInputField
          label={"Payment Amount"}
          type={"number"}
          min={0}
          name={"paymentAmount"}
        />

        <CustomInputField
          label={"Due Amount"}
          type={"number"}
          min={0}
          name={"dueAmount"}
        />
      </div>
    </div>
  );
};

export default WorkInformation;
