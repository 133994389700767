import moment from "moment";
import React from "react";

const EmploymentStatus = ({ employee, notViewer }) => {
  const {
    employment,
    employmentRemarks,
    employmentDetails,

    empCompanyName,
    empCountry,
    employmentDate,
    empDuration,
    empCurrentLocation,
    empRemarks,
  } = employee;

  return (
    <>
      {notViewer && employment && (
        <div className="lg:w-full mx-auto pb-10">
          <div className="grid lg:grid-cols-2 gap-4 dark:text-white">
            <div
              className=" text-base lg:text-lg shadow-lg p-6 rounded-2xl
          dark:shadow-gray-700"
            >
              <h2 className="text-2xl font-bold  pb-4 ">Employment Status: </h2>
              <div className="grid grid-cols-2 pb-2">
                <h2 className="font-semibold">Status :</h2>
                <h2 className="text-start capitalize break-words">
                  {employment}
                </h2>
              </div>
              {employment === "Rejected" ? (
                <>
                  <div className="grid grid-cols-2 pb-2">
                    <h2 className="font-semibold">Rejection Reason :</h2>
                    <h2 className="text-start capitalize break-words">
                      {employmentRemarks}
                    </h2>
                  </div>
                </>
              ) : (
                <></>
              )}
              {employment === "Cancelled" ? (
                <>
                  <div className="grid grid-cols-2 pb-2">
                    <h2 className="font-semibold">Cancelation Reason :</h2>
                    <h2 className="text-start capitalize break-words">
                      {employmentRemarks}
                    </h2>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmploymentStatus;
