import axios from "axios";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { updateData } from "../../hooks/update";
import { getToken } from "../../utility/Constant";

import CustomInputField from "../customInputField";
import MultipleFileInput from "../multipleFileInput/multipleFileInput";

import CitizenshipInformation from "./citizenshipInformation";

import PersonalInformation from "./personalInformation";
import {
  categorySectionCX,
  categoryTitleCX,
  formBodyCX,
  formTitleCX,
  modalBoxCX,
  modalCloseButtonCX,
  modalCX,
  modalToggleCX,
  imageUploadDiv,
  imageUploadBox,
  file,
  layer,
  iconsStyle,
  profileImageCX,
  uploadLogoText,
  fileNameCX,
  submitButtonCX,
  submitButton,
} from "./styledClass";
import WorkInformation from "./workInformation";
import Experience from "./experienceInformation";
import Employment from "./employmentInfo";
import useFetch from "../../hooks/useFetch";
import { useEffect } from "react";
import DeleteModalPopup from "../formModalUpdate/deleteModalPopup";
import ShareWithCustomSelect from "../customInputField/ShareWithCustomSelect";
import PassportInformation from "./passportStatus";

const AddUpdateEmployee = () => {
  const formRef = useRef();
  const [profileImage, setProfileImage] = useState("");
  const [fileName, setFileName] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  let [isSharedOpen, setIsSharedOpen] = useState(false);
  let [shareRemoveIdx, setShareRemoveIdx] = useState(null);

  const [mediaFiles, setMediaFiles] = useState([]);
  const [bulkFiles, setBulkFiles] = useState([]);

  const [clearance, setClearance] = useState("No");
  const [experience, setExperience] = useState("No");
  const [experienceDetails, setExperienceDetails] = useState([]);
  const [employment, setEmployment] = useState("No");
  const [employmentDetails, setEmploymentDetails] = useState([]);
  const [passStat, setPassStat] = useState("No");

  //share with state
  const [shareWith, setShareWith] = useState([]);
  const [shareWithName, setShareWithName] = useState([]);

  const handleBulkMediasAndFiles = () => {
    setMediaFiles(0);
    setBulkFiles();
    setProfileImage("");
  };

  const handleProfileImage = (event) => {
    const size = event.target.files[0].size;
    if (size < 5000000) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("upload_preset", "n9xl2szh");
      axios
        .post(
          "https://api.cloudinary.com/v1_1/dgm3nfqcg/image/upload",
          formData
        )
        .then((response) => setProfileImage(response.data.url));
      setFileName(event.target.files[0].name);
    } else {
      toast.error("Max image limit 5MB!", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // bulk image file handel start
  const handleImageFile = (file) => {
    const size = file.size;
    if (size < 5000000) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "ogv6zyim");
      axios
        .post(
          "https://api.cloudinary.com/v1_1/dgm3nfqcg/image/upload",
          formData
        )
        .then((response) => {
          //mediaFiles.push(response.data.url);
          setMediaFiles([...mediaFiles, response.data.url]);
        });
    } else {
      toast.error("Max image limit 5MB!", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleMultipleImage = (event) => {
    const files = event.target.files;
    const fileLimit = files.length;

    if (fileLimit <= 5) {
      for (let file of files) {
        handleImageFile(file);
      }
    } else {
      toast.error("Max Image Limit 5!", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // bulk image file handel End

  const handleMultipleFile = async (event) => {
    const files = event.target.files;
    const formData = new FormData();
    if (files) {
      [...files]?.map((file) => formData.append("files", file));

      axios({
        method: "post",
        url: `https://serverside.banglahaat.ro/api/v1/file/upload`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getToken(),
        },
      })
        .then(function (response) {
          //handle success
          setBulkFiles(response.data.data);
        })
        .catch(function (error) {
          //handle error
          console.log(error);
        });
    }
    // for (let file of files) {
    //   bulkFiles.push(file.name);
    // }
  };
  const nestedInputHandle = (event) => {
    let inputVal = event?.target;
    if (inputVal?.expCompanyName?.length) {
    }
  };

  // bulk file handel End

  const handleSubmit = (event) => {
    event.preventDefault();
    const testArray = [];
    const singleObject = {};
    const employmentArray = [];
    const employmentSingleObject = {};
    //share with data process
    let shareDataWith = [];
    for (let i = 0; i < shareWith.length; i++) {
      shareDataWith.push([shareWith[i], shareWithName[i]]);
    }
    const filterDate = (date) => {
      return date === "01/01/1900" ? "" : date;
    };
    const data = {
      name: event.target.name?.value,
      shareWith: shareDataWith,
      gender: event.target.gender?.value.split(",")[0],
      nationality: event.target.nationality?.value,
      dob: filterDate(event.target.dob?.value),
      disability: event.target.disability?.value.split(",")[0],
      maritalStatus: event.target.maritalStatus?.value.split(",")[0],
      address: event.target.address?.value,
      contact: event.target.contact?.value,
      email: event.target.email?.value,
      country: event.target.country?.value,
      city: event.target.city?.value,
      nidNo: event.target.nidNo?.value,
      drivingLicenseNo: event.target.drivingLicenseNo?.value,
      passportNo: event.target.passportNo?.value,
      passportExpDate: filterDate(event.target.passportExpDate?.value),
      passportStat: event.target.passportStat?.value.split(",")[0],

      policeClearance: event.target.policeClearance?.value.split(",")[0],
      policeClearanceNo: event.target.policeClearanceNo?.value,
      policeClearanceIsDate: filterDate(
        event.target.policeClearanceIsDate?.value
      ),
      photo: profileImage,
      workCategory: event.target.workCategory?.value.split(",")[1],
      dynamicId: event.target.workCategory?.value.split(",")[0],
      levels: event.target.levels?.value.split(",")[0],
      agentName: event.target.agentName?.value,
      paymentAmount: event.target.paymentAmount?.value,
      dueAmount: event.target.dueAmount?.value,
      experience: event.target.experience?.value.split(",")[0],
      // experienceDetails: expData,

      employment: event.target.employment?.value.split(",")[0],

      remarks: event.target.remarks?.value,
      addLinks: event.target.addLinks?.value,
      media: mediaFiles,
      files: bulkFiles,
    };

    // passport stat
    if (data.passportStat === "Submitted") {
      data.SubmitDate = filterDate(event.target?.SubmitDate?.value);
      data.SubmittedTo = event.target?.SubmittedTo?.value;
    } else if (data.passportStat === "Returned") {
      data.returnDate = filterDate(event.target?.returnDate?.value);
      data.returnedTo = event.target?.returnedTo?.value;
    } else {
      data.SubmitDate = "";
      data.SubmittedTo = "";
      data.returnDate = "";
      data.returnedTo = "";
    }

    //employment stat
    if (data.employment === "Rejected" || "Cancelled") {
      data.employmentRemarks = event.target?.empRemarks?.value;
    } else {
      data.employmentRemarks = "";
    }

    //experience data
    event.target.expCompanyName?.name?.length > 0
      ? (singleObject.expCompanyName = event.target.expCompanyName.value)
      : event.target.expCompanyName?.forEach((element, i) => {
          testArray[i] = { ...testArray[i], [element.name]: element.value };
        });

    event.target.expCountry?.name?.length > 0
      ? (singleObject.expCountry = event.target.expCountry.value)
      : event.target.expCountry?.forEach((element, i) => {
          testArray[i] = { ...testArray[i], [element.name]: element.value };
        });

    event.target.expResponsibilities?.name?.length > 0
      ? (singleObject.expResponsibilities =
          event.target.expResponsibilities.value)
      : event.target.expResponsibilities?.forEach((element, i) => {
          testArray[i] = { ...testArray[i], [element.name]: element.value };
        });

    event.target.expDuration?.name?.length > 0
      ? (singleObject.expDuration = event.target.expDuration.value)
      : event.target.expDuration?.forEach((element, i) => {
          testArray[i] = { ...testArray[i], [element.name]: element.value };
        });

    event.target.expRemarks?.name?.length > 0
      ? (singleObject.expRemarks = event.target.expRemarks.value)
      : event.target.expRemarks?.forEach((element, i) => {
          testArray[i] = { ...testArray[i], [element.name]: element.value };
        });

    //employment data
    event.target.empCompanyName?.name?.length > 0
      ? (employmentSingleObject.empCompanyName =
          event.target.empCompanyName.value)
      : event.target.empCompanyName?.forEach((element, i) => {
          employmentArray[i] = {
            ...employmentArray[i],
            [element.name]: element.value,
          };
        });

    event.target.empCountry?.name?.length > 0
      ? (employmentSingleObject.empCountry = event.target.empCountry.value)
      : event.target.empCountry?.forEach((element, i) => {
          employmentArray[i] = {
            ...employmentArray[i],
            [element.name]: element.value,
          };
        });

    event.target.employmentDate?.name?.length > 0
      ? (employmentSingleObject.employmentDate =
          event.target.employmentDate.value)
      : event.target.employmentDate?.forEach((element, i) => {
          employmentArray[i] = {
            ...employmentArray[i],
            [element.name]: element.value,
          };
        });

    event.target.empDuration?.name?.length > 0
      ? (employmentSingleObject.empDuration = event.target.empDuration.value)
      : event.target.empDuration?.forEach((element, i) => {
          employmentArray[i] = {
            ...employmentArray[i],
            [element.name]: element.value,
          };
        });

    event.target.empCurrentLocation?.name?.length > 0
      ? (employmentSingleObject.empCurrentLocation =
          event.target.empCurrentLocation.value)
      : event.target.empCurrentLocation?.forEach((element, i) => {
          employmentArray[i] = {
            ...employmentArray[i],
            [element.name]: element.value,
          };
        });

    event.target.empRemarks?.name?.length > 0
      ? (employmentSingleObject.empRemarks = event.target.empRemarks.value)
      : event.target.empRemarks?.forEach((element, i) => {
          employmentArray[i] = {
            ...employmentArray[i],
            [element.name]: element.value,
          };
        });

    //experience data
    if (singleObject?.expCompanyName?.length > 0) {
      data.experienceDetails = [singleObject];
    } else {
      data.experienceDetails = testArray;
    }

    //employment data
    if (employmentSingleObject?.empCompanyName?.length > 0) {
      data.employmentDetails = [employmentSingleObject];
    } else {
      data.employmentDetails = employmentArray;
    }
    //console.log(data, "form post");
    updateData({
      endPoint: `form`,
      data: data,
      method: "POST",
      modalCloseID: "addEmployee",
      bulkReset: handleBulkMediasAndFiles,
    });
    // handleBulkMediasAndFiles();
  };

  //share with function
  const [shareUser, setShareUser] = useState([["0", "Remove Share"]]);

  //fetch user data
  const [users] = useFetch("user/list");

  //filter and update state
  useEffect(() => {
    let temp = [];
    let clients = users?.filter(function (person) {
      return person.role === "client" || person.role === "client-admin";
    });
    for (let data of clients) {
      temp.push([data.id, data?.email?.split("@")[0]]);
    }
    setShareUser([["0", "Remove Share"], ...temp]);
  }, [users]);

  // for removing all share
  function handleAllShareRemove() {
    setShareWith([]);
    setShareWithName([]);
    setIsSharedOpen(false);
  }

  const handleShareWith = (event) => {
    const inputVal = event.target.value.split(",")[0];
    const inputName = event.target.value.split(",")[1];
    if (inputName === "Remove Share") {
      if (shareWithName?.length > 0) {
        setIsSharedOpen(true);
      } else {
        toast.warning(`Not shared with anyone`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else if (inputName === undefined) {
      console.log("lol");
    } else {
      if (shareWith.includes(inputVal)) {
        toast.warning(`Already shared with ${inputName}`, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setShareWith((prevShareWith) => [...prevShareWith, inputVal]);
        setShareWithName((prevShareWithName) => [
          ...prevShareWithName,
          inputName,
        ]);
      }
    }
  };

  // Handle Share Remove
  const handleShareRemove = () => {
    const updatedShareWith = [...shareWith];
    updatedShareWith.splice(shareRemoveIdx, 1);
    setShareWith(updatedShareWith);

    const updatedShareWithName = [...shareWithName];
    updatedShareWithName?.splice(shareRemoveIdx, 1);
    setShareWithName(updatedShareWithName);
    closeModal();
  };

  // Confirmation Modal
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function deleteModalOpener(e, index) {
    e.preventDefault();
    setShareRemoveIdx(index);
    openModal();
  }

  return (
    <div>
      <input type="checkbox" id="addEmployee" className={modalToggleCX} />
      <div className={modalCX}>
        <div className={modalBoxCX}>
          <label
            htmlFor="addEmployee"
            onClick={() => {
              formRef.current.reset();
              handleBulkMediasAndFiles();
              setClearance("No");
              setExperience("No");
            }}
            className={modalCloseButtonCX}
          >
            ✕
          </label>
          <form ref={formRef} onSubmit={handleSubmit} className={formBodyCX}>
            <h2 className={formTitleCX}>Add Employee</h2>
            <div className="flex-row md:flex items-center justify-between gap-4 mb-10">
              <div className={profileImageCX}>
                <div className={imageUploadDiv}>
                  <div
                    className={imageUploadBox}
                    style={{
                      backgroundImage: `url(${profileImage})`,
                    }}
                  >
                    <input
                      type="file"
                      name="photo"
                      className={file}
                      onChange={handleProfileImage}
                      formEncType="multipart/form-data"
                      accept="image/*"
                    />
                    <div className={layer(profileImage)}>
                      <div className={iconsStyle}>
                        <img
                          src="https://mez.ink/mezink-web/_next/static/images/invoice/imageLogo.png"
                          alt="uploadImageThumbnail"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* profile img section */}
                <div>
                  <h2 className={uploadLogoText}>Upload Image</h2>
                  <h2 className={fileNameCX}>
                    {fileName ? fileName : "No file chosen"}
                  </h2>
                </div>
              </div>

              {/* share with drop down section */}
              <div className="mt-10 md:mt-0 flex justify-end">
                <ShareWithCustomSelect
                  label={"Share With"}
                  type={"text"}
                  name={"shareWith"}
                  select={true}
                  options={shareUser}
                  defaultValue={"Select An Option"}
                  handleInput={handleShareWith}
                />
              </div>
            </div>

            <div className="flex md:justify-end flex-wrap gap-3 items-center mt-[-20px]">
              <span className="font-bold dark:text-white md:inline block w-full md:w-fit">
                Shared With:
              </span>
              {shareWithName?.length ? (
                shareWithName?.map((user, index) => (
                  <div className="">
                    {/* -----------------------------{shareWith single part}---------------------- */}
                    <span
                      key={index}
                      className="flex flex-wrap justify-between gap-2 p-1.5 px-2 bg-gray-200 rounded-md"
                    >
                      <h3 className="capitalize my-[auto]">{user}</h3>
                      <button
                        onClick={(e) => {
                          deleteModalOpener(e, index);
                        }}
                        className="btn btn-sm btn-circle dark:bg-white dark:text-[#303640] hover:bg-red-600 hover:border-none"
                      >
                        X
                      </button>
                    </span>
                  </div>
                ))
              ) : (
                <span> Not yet shared</span>
              )}
            </div>
            {/* Modal for share remove */}
            <DeleteModalPopup
              isOpen={isOpen}
              closeModal={closeModal}
              handleDelete={handleShareRemove}
            />
            {/* Modal for all share remove */}
            <DeleteModalPopup
              isOpen={isSharedOpen}
              closeModal={() => setIsSharedOpen(false)}
              handleDelete={handleAllShareRemove}
            />
            {/* -------------------------------{shareWith close}------------------------------- */}

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Personal Attachments:</h2>

              <div className="flex flex-col gap-4">
                <MultipleFileInput
                  fileLen={mediaFiles?.length}
                  fileText="Documents Image"
                  accept="image/*"
                  acceptFormate="Images"
                  handelFile={handleMultipleImage}
                />
                <MultipleFileInput
                  fileLen={bulkFiles?.length}
                  fileText="Files"
                  accept=".pdf, .doc, .docx, .xls, .xlsx, .txt, .ppt, .pptx, .mp3, .mp4, .mkv, .mov"
                  acceptFormate="Files"
                  handelFile={handleMultipleFile}
                />
              </div>
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Personal Details:</h2>
              <PersonalInformation />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Citizenship Details:</h2>
              <CitizenshipInformation />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            {/* passport status */}
            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Passport Status:</h2>
              <PassportInformation
                passStat={passStat}
                setPassStat={setPassStat}
              />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Work Details:</h2>
              <WorkInformation
                clearance={clearance}
                setClearance={setClearance}
              />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Experience:</h2>
              <Experience
                experience={experience}
                setExperience={setExperience}
                experienceDetails={experienceDetails}
                setExperienceDetails={setExperienceDetails}
              />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Employment Status:</h2>
              <Employment
                employment={employment}
                setEmployment={setEmployment}
                employmentDetails={employmentDetails}
                setEmploymentDetails={setEmploymentDetails}
              />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Remarks:</h2>
              <CustomInputField
                label={"Notes"}
                type={"text"}
                name={"remarks"}
                paragraph="true"
              />
            </div>
            <hr className="mt-5 mb-4 border-gray-300 border-[1.5px]" />

            <div className={categorySectionCX}>
              <h2 className={categoryTitleCX}>Additional Information:</h2>
              <CustomInputField
                label={"Links"}
                type={"text"}
                name={"addLinks"}
                paragraph="true"
              />
            </div>

            <div className={submitButtonCX}>
              <button type="submit" className={submitButton}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUpdateEmployee;
