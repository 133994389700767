import "./style.css";

export const inputContainerCX = (spanFull) => {
  return spanFull
    ? "border-2 border-solid rounded-lg border-gray-400 px-3 col-span-full"
    : "border-2 border-solid rounded-lg border-gray-400 px-3 h-fit";
};

export const legendCx = "px-2 font-semibold dark:text-white";

export const inputCX =
  "customInput  dark:bg-[#2C2E33]  dark:text-white border-2";
