import React from "react";
import CustomInputField from "../customInputField";
import { categorySectionCX } from "./styledClass";

const AddExp = ({ item, number }) => {
  const {
    expCompanyName,
    expCountry,
    expResponsibilities,
    expDuration,
    expRemarks,
  } = item ?? {};

  return (
    <div id={number} className={`${categorySectionCX} space-y-4`}>
      <CustomInputField
        label={"Company Name"}
        type={"text"}
        name={"expCompanyName"}
        defaultValue={expCompanyName || ""}
        required={true}
      />

      <CustomInputField
        label={"Country"}
        type={"text"}
        name={"expCountry"}
        defaultValue={expCountry || ""}
      />

      <CustomInputField
        label={"Responsibilities"}
        type={"text"}
        name={"expResponsibilities"}
        defaultValue={expResponsibilities || ""}
      />

      <CustomInputField
        label={"Duration"}
        type={"text"}
        name={"expDuration"}
        defaultValue={expDuration || ""}
      />

      <CustomInputField
        label={"Remarks"}
        type={"text"}
        name={"expRemarks"}
        defaultValue={expRemarks || ""}
        paragraph
        rows={2}
      />
    </div>
  );
};

export default AddExp;
