import React, { useState } from "react";
import CustomInputField from "../customInputField";
import {
  btnContainer,
  categoryTitleCX,
  expDelBtn,
  sectionGridCX,
} from "./styledClass";
import AddExp from "./addExp";

const WorkInformation = ({
  experience,
  setExperience,
  experienceDetails,
  setExperienceDetails,
}) => {
  const [keyCounter, setKeyCounter] = useState(1);

  const [element, setElement] = useState([
    <div key={keyCounter}>
      <AddExp number={1} />
    </div>,
  ]);

  const removeElement = (e, index) => {
    e.preventDefault();

    const updatedItems = element.filter(
      (item) => item.key !== index.toString()
    );
    setElement([...updatedItems]);
  };

  const addElement = (e) => {
    e.preventDefault();
    setKeyCounter(keyCounter + 1);
    const newElement = (
      <div key={keyCounter}>
        <AddExp number={element?.length + 1} />
      </div>
    );
    setElement([...element, newElement]);
  };

  const handleClearance = (event) => {
    setExperience(event.target.value.split(",")[0]);
  };

  return (
    <div>
      <div className="flex flex-col">
        <div className="w-full lg:w-fit min-w-[18rem]">
          <CustomInputField
            label={"Experience"}
            type={"text"}
            name={"experience"}
            select={true}
            options={[
              ["No", "No"],
              ["Yes", "Yes"],
            ]}
            handleInput={handleClearance}
          />
        </div>

        {experience === "Yes" && (
          <>
            <div>
              <ul className="pt-4 pb-4 grid grid-cols-1 lg:grid-cols-2 gap-x-4">
                {element.map((item, index) => (
                  <li key={index}>
                    <div className="flex justify-between items-center mt-0">
                      <h2 className={categoryTitleCX}>
                        Experience {index + 1}:
                      </h2>
                      <button
                        type="button"
                        onClick={(e) => {
                          removeElement(e, item.key);
                        }}
                        className={`w-8 h-8 rounded-full bg-[#2C2E33] text-white hover:bg-red-500 transition-colors duration-100`}
                      >
                        ✕
                      </button>
                    </div>
                    {item}
                  </li>
                ))}
              </ul>
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-4">
                <button
                  type="button"
                  onClick={(e) => {
                    addElement(e);
                  }}
                  className="bg-gray-200 border border-transparent py-3.5 w-full lg:w-full px-4 font-medium rounded-lg h-fit self-end hover:bg-gray-300 transition-colors duration-100 dark:hover:bg-transparent dark:hover:text-gray-200 dark:hover:border-gray-200"
                >
                  Add {element?.length > 0 && "Another"} Experience +
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WorkInformation;
