import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loading from "../component/shared/loading/Loading";
import useSession from "../hooks/useSession";

function AdminAuth({ children }) {
  const [user, loading] = useSession();
  const location = useLocation();

  if (loading) {
    return <Loading />;
  }
  const { role } = user;
  if (role !== "admin") {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default AdminAuth;
