import React, { useEffect, useState } from "react";
import Footer from "../../component/shared/footer";
import Loading from "../../component/shared/loading/Loading";
import NavBar from "../../component/shared/navbar";
import "../../component/tableComponent/style.css";

import useFetch from "../../hooks/useFetch";
import useSession from "../../hooks/useSession";
import HomePageStructure from "./HomePageStructure";
import { pageContainer } from "./styledClass";

const HomePage = () => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );
  const [searchBarActive, setSearchbarActive] = useState(false);
  const [employee, , employeeLoading] = useFetch("form");
  const [user, userLoading] = useSession();
  const [searchedData, setSearchedData] = useState([]);
  const [filter, setFilter] = useState([]);
  let tempData = [];
  // if (userLoading || employeeLoading) {
  //   return <Loading />;
  // }

  //filter section
  if (filter?.length) {
    let temp = [];
    let passTemp = [];
    for (let filterOp of filter) {
      let data;

      data = employee.filter((item) => {
        return item.employment === filterOp;
      });
      temp.push(...data);

      const options = ["Submitted", "Not Submitted", "Returned"];
      if (options.indexOf(filterOp) !== -1 && temp.length) {
        const filData = temp.filter((item) => {
          return item.passportStat === filterOp;
        });
        if (filData.length) {
          passTemp.push(...filData);
        } else {
          passTemp = [];
          temp = [];
        }
      } else if (options.indexOf(filterOp) !== -1) {
        data = employee.filter((item) => {
          return item.passportStat === filterOp;
        });
        data.length ? passTemp.push(...data) : (passTemp = []);
      } else {
        // console.log("working well");
      }
    }
    if (passTemp.length) {
      temp = passTemp;
    }
    //final array
    tempData.push(temp);
  }

  //search section
  const handleSearch = (e) => {
    const input = e.target.value.toLowerCase();
    setSearchedData(
      employee.filter(
        (item) =>
          item.name.toLowerCase().search(input) >= 0 ||
          item.remarks.toLowerCase().search(input) >= 0 ||
          item.country.toLowerCase().search(input) >= 0 ||
          item.experienceDetails.some(
            (item) => item.expCompanyName.toLowerCase().search(input) >= 0
          ) ||
          item.experienceDetails.some(
            (item) => item.expResponsibilities.toLowerCase().search(input) >= 0
          ) ||
          item.experienceDetails.some(
            (item) => item.expDuration.toLowerCase().search(input) >= 0
          ) ||
          item.experienceDetails.some(
            (item) => item.expCountry.toLowerCase().search(input) >= 0
          ) ||
          item.experienceDetails.some(
            (item) => item.expRemarks.toLowerCase().search(input) >= 0
          ) ||
          item.workCategory.toLowerCase().search(input) >= 0 ||
          item.dynamicId.toLowerCase().search(input) >= 0 ||
          item.contact.search(input) >= 0 ||
          item.nidNo.toLowerCase().search(input) >= 0 ||
          item.passportNo.toLowerCase().search(input) >= 0 ||
          item.address.toLowerCase().search(input) >= 0 ||
          item.levels?.toLowerCase().search(input) >= 0 ||
          item.agentName.toLowerCase().search(input) >= 0
      )
    );
    setSearchbarActive(e.target.value ? true : false);
  };

  return (
    <main className={pageContainer}>
      <NavBar
        user={user}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        setFilter={setFilter}
      />
      <HomePageStructure
        // employee={searchBarActive ? searchedData : employee}
        employee={
          tempData.length
            ? tempData[0]
            : searchBarActive
            ? searchedData
            : employee
        }
        user={user}
        searchBarActive={searchBarActive}
        handleSearch={handleSearch}
        loading={userLoading || employeeLoading}
        filter={filter}
        setFilter={setFilter}
      />
      <Footer darkTheme={darkMode} />
    </main>
  );
};

export default HomePage;
