import moment from "moment";
import React from "react";

const PersonalInformation = ({ employee }) => {
  const { nationality, nidNo, drivingLicenseNo, passportNo, passportExpDate } =
    employee;
  return (
    <div className=" text-base lg:text-lg shadow-lg  p-6 rounded-2xl dark:shadow-gray-700">
      <h2 className="text-2xl font-bold pb-4">Personal Information: </h2>

      <div className="grid grid-cols-2 pb-2">
        <h2 className=" font-semibold ">Nationality:</h2>
        <h2 className="text-start break-words">{nationality}</h2>
      </div>

      {nidNo && (
        <div className="grid grid-cols-2 pb-2">
          <h2 className=" font-semibold">NID Number:</h2>
          <h2 className="text-start break-words">{nidNo}</h2>
        </div>
      )}
      {drivingLicenseNo && (
        <div className="grid grid-cols-2 pb-2 ">
          <h2 className=" font-semibold">Driving License Number:</h2>
          <h2 className="text-start break-words">{drivingLicenseNo}</h2>
        </div>
      )}
      {passportNo && (
        <div className="grid grid-cols-2 pb-2">
          <h2 className="font-semibold">Passport Number: </h2>
          <h2 className="text-start break-words">{passportNo}</h2>
        </div>
      )}
      {passportExpDate && (
        <div className="grid grid-cols-2 pb-2">
          <h2 className=" font-semibold">Passport Expire Date:</h2>
          <h2 className="text-start">
            {moment(passportExpDate).format("MM-DD-YYYY")}
          </h2>
        </div>
      )}
    </div>
  );
};

export default PersonalInformation;
