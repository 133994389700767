import React, { Fragment, useState } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import { filterButton, filterButtonCOntainer, filterIcon } from "./styledClass";

const FilterOption = ({ filter, setFilter, trigger }) => {
  const filters = [
    {
      id: "employed",
      name: "Employment Status",
      options: [
        { value: "Employed", label: "Employed", checked: false },
        { value: "On Process", label: "On Process", checked: false },
        { value: "Rejected", label: "Rejected", checked: false },
        { value: "Waiting List", label: "Waiting List", checked: false },
        { value: "Cancelled", label: "Cancelled", checked: false },
      ],
    },
    {
      id: "passport",
      name: "Passport Status",
      options: [
        { value: "Submitted", label: "Submitted", checked: false },
        { value: "Not Submitted", label: "Not Submitted", checked: false },
        { value: "Returned", label: "Returned", checked: false },
      ],
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const checkBoxEvent = (event) => {
    const { checked, value } = event.target;
    if (checked) {
      if (!filter.includes(value)) {
        setFilter([...filter, value]);
      }
    } else {
      const arr = filter.filter((item) => {
        return item !== value;
      });
      setFilter(arr);
    }
  };

  return (
    <>
      <label htmlFor={trigger} className={filterButtonCOntainer}>
        <div className={filterButton}>
          <div className={filterIcon}>
            <i class="fa-solid fa-bars fa-fw "></i>
            {/* <i className="fa-sharp fa-solid fa-filter"></i> */}
          </div>
        </div>
      </label>
    </>
  );
};

export default FilterOption;
