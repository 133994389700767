import React from "react";
import CustomInputField from "../customInputField";
import { categorySectionCX } from "./styledClass";

const AddExp = ({ item, number }) => {
  const {
    empCompanyName,
    empCountry,
    employmentDate,
    empDuration,
    empCurrentLocation,
    empRemarks,
  } = item ?? {};

  return (
    <div id={number} className={`${categorySectionCX} space-y-4`}>
      <CustomInputField
        label={"Company Name"}
        type={"text"}
        name={"empCompanyName"}
        defaultValue={empCompanyName || ""}
        required={true}
      />

      <CustomInputField
        label={"Country"}
        type={"text"}
        name={"empCountry"}
        defaultValue={empCountry || ""}
      />

      <CustomInputField
        label={"Employment Date"}
        type={"text"}
        name={"employmentDate"}
        defaultValue={employmentDate || ""}
      />

      <CustomInputField
        label={"Duration"}
        type={"text"}
        name={"empDuration"}
        defaultValue={empDuration || ""}
      />

      <CustomInputField
        label={"Current Location"}
        type={"text"}
        name={"empCurrentLocation"}
        defaultValue={empCurrentLocation || ""}
      />
      <CustomInputField
        label={"Remarks"}
        type={"text"}
        name={"empRemarks"}
        defaultValue={empRemarks || ""}
        paragraph
        rows={2}
      />
    </div>
  );
};

export default AddExp;
