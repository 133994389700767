import React from "react";
import { inputContainerCX, inputCX, legendCx } from "./styledClass";
import { useState } from "react";

const ShareWithCustomSelect = ({
  label,
  type,
  name,
  defaultValue,
  spanFull,
  paragraph,
  select,
  options,
  required,
  handleInput,
  uppercase,
  rows,
}) => {
  const [state, setState] = useState(null);
  let updatedOptions = [];
  let showValue;
  if (select && defaultValue) {
    if (typeof defaultValue === "object") {
      updatedOptions = options.filter((item) => item[0] !== defaultValue[0]);
      showValue = defaultValue[1];
    } else if (typeof defaultValue === "string") {
      updatedOptions = options.filter(
        (item) =>
          item[1] !==
          defaultValue?.charAt(0).toUpperCase() + defaultValue?.slice(1)
      );

      showValue = defaultValue;
    } else {
      updatedOptions = options.filter((item) => item[0] !== defaultValue);
      showValue = defaultValue;
    }
  } else {
    updatedOptions = options;
    showValue = defaultValue;
  }

  function handleCng(event) {
    handleInput(event);
    // event.selectedIndex = 0;
  }

  return (
    <fieldset className={`${inputContainerCX(spanFull)} w-full md:w-fit`}>
      <legend className={legendCx}>
        <div className="flex gap-2">
          <div>{label}</div>
          <div className="scale-[2] mt-[6px] text-red-500">
            {required && "*"}
          </div>
        </div>
      </legend>
      {select ? (
        <select
          className={inputCX + " capitalize"}
          name={name}
          value={showValue}
          onChange={(e) => handleCng(e)}
        >
          {showValue && <option value={showValue}>{showValue}</option>}
          {updatedOptions.map((item, index) => (
            <option key={index} value={[item[0], item[1]]}>
              {item[1]}
            </option>
          ))}
        </select>
      ) : paragraph ? (
        <textarea
          type={type}
          name={name}
          defaultValue={showValue}
          rows={rows ? rows : "4"}
          cols="50"
          className={inputCX}
          required={required}
        />
      ) : (
        <input
          type={type}
          name={name}
          defaultValue={showValue ? showValue : ""}
          className={inputCX}
          required={required}
          style={uppercase && { textTransform: "uppercase" }}
          onKeyDown={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
        />
      )}
    </fieldset>
  );
};

export default ShareWithCustomSelect;
