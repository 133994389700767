import React from "react";
import {
  serialThStyle,
  tableActionHeadStyle,
  tableHeadStyle,
} from "./styledClass";

const TableHeadAdmin = () => {
  return (
    <thead className={tableHeadStyle}>
      <tr>
        <th className={serialThStyle}>serial No</th>
        <th>Name</th>
        <th>User Name</th>
        <th>Created Date</th>
        <th>Status</th>
        <th className={tableActionHeadStyle}>Action</th>
      </tr>
    </thead>
  );
};

export default TableHeadAdmin;
