import moment from "moment";
import React from "react";

const WorkDetails = ({ employee, notViewer }) => {
  const {
    agentName,
    policeClearance,
    paymentAmount,
    dueAmount,
    policeClearanceNo,
    policeClearanceIsDate,
  } = employee;
  return (
    <div className=" text-base lg:text-lg shadow-lg p-6 rounded-2xl dark:shadow-gray-700">
      <h2 className="text-2xl font-bold  pb-4 ">Work Details: </h2>

      {notViewer && agentName && (
        <div className="grid grid-cols-2 pb-2">
          <h2 className="font-semibold">Agent Name :</h2>
          <h2 className="text-start capitalize break-words">{agentName}</h2>
        </div>
      )}

      <div className="grid grid-cols-2 pb-2">
        <h2 className=" font-semibold">Police Clearance:</h2>
        <h2 className="text-start">{policeClearance.split(",")[0]}</h2>
      </div>

      {policeClearance.split(",")[0] === "Yes" && (
        <>
          <div className="grid grid-cols-2 pb-2 ">
            <h2 className=" font-semibold">Police Clearance Number:</h2>
            <h2 className="text-start break-words">{policeClearanceNo}</h2>
          </div>

          <div className="grid grid-cols-2 pb-2">
            <h2 className="font-semibold">Police Clearance Date:</h2>
            <h2 className="text-start">
              {moment(policeClearanceIsDate).format("MM-DD-YYYY")}
            </h2>
          </div>
        </>
      )}

      {notViewer && paymentAmount && (
        <div className="grid grid-cols-2 pb-2">
          <h2 className="font-semibold">Payment Amount :</h2>
          <h2 className="text-start break-words">{paymentAmount} ৳</h2>
        </div>
      )}

      {notViewer && dueAmount && (
        <div className="grid grid-cols-2 pb-2">
          <h2 className="font-semibold">Due Amount :</h2>
          <h2 className="text-start break-words">{dueAmount} ৳</h2>
        </div>
      )}
    </div>
  );
};

export default WorkDetails;
