import React, { useEffect } from "react";
import logo from "./../../assets/logo.svg";
import { useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { updateData } from "../../hooks/update";
import {
  alignBodyStyle,
  divGridItemStyle,
  forgotPassStyle,
  formBodyStyle,
  formInputStyle,
  imageDivStyle,
  imageStyle,
  loginStyle,
  registerStyle,
} from "./styledClass";

const Login = () => {
  const [forgotPass, setForgotPass] = useState("Forgot your password?");
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );
  const navigate = useNavigate();
  const email = useRef("");
  const password = useRef("");

  useEffect(() => {
    const isDarkMode = localStorage.getItem("darkMode") === "true";
    setDarkMode(isDarkMode);
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.remove("light");
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
      document.documentElement.classList.add("light");
    }
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  const handleSwitch = () => {
    setDarkMode(!darkMode);
  };

  const handleForgotPass = () => {
    setForgotPass("Contact Admin!");
  };

  const login = (e) => {
    e.preventDefault();
    sessionStorage.clear();
    const data = {
      email: email.current.value,
      password: password.current.value,
    };
    updateData({
      endPoint: `user/login`,
      data: data,
      method: "POST",
      login: true,
      navigate: navigate,
    });
  };

  return (
    <div>
      <button
        type="button"
        onClick={handleSwitch}
        className="text-3xl absolute hidden lg:block lg:right-10 lg:top-10"
      >
        {darkMode ? (
          <i className="fa-solid fa-moon"></i>
        ) : (
          <i className="fas fa-sun text-[#292929!important]" />
        )}
      </button>
      <div className={alignBodyStyle}>
        <div className={divGridItemStyle}>
          <div className={registerStyle}>
            <div className={imageDivStyle}>
              <img src={logo} alt="" className={imageStyle} />
            </div>

            <h2 className={loginStyle}>Login</h2>

            <form onSubmit={login} className={formBodyStyle}>
              <div className="form__field">
                <input
                  ref={email}
                  className={formInputStyle}
                  type="text"
                  placeholder="Email"
                />
              </div>

              <div className="form__field">
                <input
                  ref={password}
                  className={formInputStyle}
                  type="password"
                  placeholder="Password"
                />
              </div>

              <div className="form__field">
                <input
                  className={formInputStyle}
                  type="submit"
                  value="Sign In"
                />
              </div>
            </form>
            <button
              type="button"
              onClick={handleForgotPass}
              className={forgotPassStyle}
            >
              {forgotPass}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Login;
