import React from "react";

import { addButton, addIcon, buttonCOntainer } from "./styledClass";

const AddButton = ({ trigger }) => {
  return (
    <label htmlFor={trigger} className={buttonCOntainer}>
      <div className={addButton}>
        <div className={addIcon}>
          <i className="fa-sharp fa-solid fa-plus"></i>
        </div>
      </div>
    </label>
  );
};

export default AddButton;
