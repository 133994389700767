import React from "react";

import {
  actionBoxCX,
  iconBodyCX,
  iconBoxCX,
  iconCX,
  inputToggleCX,
  modalCX,
  noButtonCX,
  subTitleCX,
  titleCX,
  yesButtonCX,
} from "../../component/customeAlert/styledClass";

const DeleteModal = ({ deleteId, id, handleDelete }) => {
  return (
    <div className="">
      <input type="checkbox" id={id} className={inputToggleCX} />
      <div className={modalCX}>
        <div className={iconBodyCX}>
          <h1 className={iconBoxCX}>
            <i className={iconCX}></i>
          </h1>
          <h3 className={titleCX}>Are you sure?</h3>
          <p className={subTitleCX}>You won't be able to revert this!</p>
          <div className={actionBoxCX}>
            <label
              id={deleteId}
              onClick={handleDelete}
              htmlFor={id}
              className={yesButtonCX}
            >
              Yes
            </label>
            <label htmlFor={id} className={noButtonCX}>
              No
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
