import moment from "moment";
import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";
import DatePic from "../datePicker";

const CitizenshipInformation = ({ uniqueEmployee }) => {
  const { nationality, nidNo, passportNo, passportExpDate } = uniqueEmployee;
  return (
    <div>
      <div className={sectionGridCX}>
        <CustomInputField
          label={"Nationality"}
          type={"text"}
          name={"nationality"}
          defaultValue={nationality}
          required={true}
        />

        <CustomInputField
          label={"NID Number"}
          type={"text"}
          name={"nidNo"}
          defaultValue={nidNo}
        />

        <CustomInputField
          label={"Passport Number"}
          type={"text"}
          name={"passportNo"}
          defaultValue={passportNo}
          uppercase={true}
        />

        {/* <CustomInputField
          label={"Passport Expire Date"}
          type={"date"}
          name={"passportExpDate"}
          defaultValue={moment(passportExpDate).format("YYYY-MM-DD")}
        /> */}
        <DatePic
          label={"Passport Expire Date"}
          name={"passportExpDate"}
          defaultValue={passportExpDate}
        />
      </div>
    </div>
  );
};

export default CitizenshipInformation;
