import React from "react";
import { useState } from "react";
import AddButton from "../../component/addButton";
import AddUser from "../../component/addUserForm";
import TableAdmin from "../../component/tableComponentAdmin/TableAdmin";
import {
  addButtonContainer,
  homeContainer,
  tableContainer,
} from "./styledClass";

const AdminPanelStructure = ({ users }) => {
  const [updateUserId, setUpdateUserId] = useState("");
  const handleUpdateUserId = (event) => {
    setUpdateUserId(event.target.id);
  };
  return (
    <section className={homeContainer}>
      <div className={addButtonContainer}>
        <AddButton trigger="addUser" />
      </div>

      <div className={tableContainer}>
        <TableAdmin
          users={users}
          updateUserId={updateUserId}
          handleUpdateUserId={handleUpdateUserId}
        />
      </div>
      <AddUser />
    </section>
  );
};

export default AdminPanelStructure;
