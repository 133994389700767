import React, { useState } from "react";
import DatePicker from "tailwind-datepicker-react";
import { inputContainerCX, legendCx } from "../customInputField/styledClass";
import "./style.css";

const DatePic = ({ label, required, name, defaultValue, spanFull }) => {
  /* const options1 = {
    title: "Select a Date",
    autoHide: true,
    todayBtn: false,
    clearBtn: true,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("1950-01-01"),
    theme: {
      background: "bg-white dark:bg-gray-800",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "bg-gray-200 dark:bg-red-500",
      input: "",
      inputIcon: "",
      selected: "",
    },
    icons: {
      // () => ReactElement | JSX.Element
      prev: () => <span>Previous</span>,
      next: () => <span>Next</span>,
    },
    datepickerClassNames: "top-12",
    defaultDate: new Date("2022-01-01"),
    language: "en",
  }; */

  const options = {
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    defaultDate: defaultValue ? new Date(defaultValue) : new Date("1-1-1900"),
    inputNameProp: name,
    inputDateFormatProp: {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    },
    theme: {
      background:
        "bg-white dark:bg-[#2C2E33] border border-[#767676] dark:border-white",
      todayBtn: "bg-[#1967D2]",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "bg-white border-none",
      inputIcon: "",
      selected: "bg-[#1967D2] !text-gray-50",
    },
  };

  const [show, setShow] = useState(false);
  const handleClose = (state) => {
    setShow(state);
  };

  return (
    <fieldset
      className={`${inputContainerCX(spanFull)} w-full ${
        defaultValue === "Select An Option" && "w-fit"
      }`}
    >
      <legend className={legendCx}>
        <div className="flex gap-2">
          <div>{label}</div>
          <div className="scale-[2] mt-[1px] text-red-500">
            {required && "*"}
          </div>
        </div>
      </legend>

      <DatePicker
        show={show}
        setShow={handleClose}
        options={options}
        //onChange={handleChange}
        classNames="relative customDesign"
      />
    </fieldset>
  );
};

export default DatePic;

{
  /* <fieldset
      className={`${inputContainerCX(spanFull)} w-full ${
        defaultValue === "Select An Option" && "w-fit"
      }`}
    >
      <legend className={legendCx}>
        <div className="flex gap-2">
          <div>{label}</div>
          <div className="scale-[2] mt-[6px] text-red-500">
            {required && "*"}
          </div>
        </div>
      </legend>
      <Datepicker
        options={options}
        onChange={handleChange}
        show={show}
        setShow={handleClose}
      />

      <DatePicker
        name={name}
        show={show}
        setShow={(state) => setShow(state)}
        options={options}
        classNames="absolute"
      />
    </fieldset> */
}
