import React from "react";

const ImageLightBox = ({ image, index }) => {
  return (
    <div>
      <input type="checkbox" id={index} className="modal-toggle p-0" />
      <label htmlFor={index} className="modal cursor-pointer p-0 ">
        <label className="modal-box rounded-md p-0 " htmlFor="">
          <label
            htmlFor={index}
            className="text-xl cursor-pointer absolute right-5 top-4 text-white text-bold"
          >
            ✕
          </label>
          <div className="flex justify-center w-[100%] max-h-[80vh] dark:bg-[#292929]">
            <img src={image} alt="gallery" className="w-full" />
          </div>
        </label>
      </label>
    </div>
  );
};

export default ImageLightBox;
