import React from "react";
import CustomInputField from "../customInputField";
import { categorySectionCX } from "./styledClass";

const AddExp = ({ number, experienceDetails, setExperienceDetails }) => {
  return (
    <div id={number} className={`${categorySectionCX} space-y-3`}>
      <CustomInputField
        label={"Company Name"}
        type={"text"}
        name={"expCompanyName"}
        required={true}
      />

      <CustomInputField label={"Country"} type={"text"} name={"expCountry"} />

      <CustomInputField
        label={"Responsibilities"}
        type={"text"}
        name={"expResponsibilities"}
      />

      <CustomInputField label={"Duration"} type={"text"} name={"expDuration"} />

      <CustomInputField
        label={"Remarks"}
        type={"text"}
        name={"expRemarks"}
        paragraph
        rows={2}
      />
    </div>
  );
};

export default AddExp;
