import moment from "moment";
import React from "react";

const PassportDetails = ({ employee, notViewer }) => {
  const { passportStat, SubmitDate, SubmittedTo, returnDate, returnedTo } =
    employee;
  return (
    <>
      {notViewer && passportStat && (
        <div className=" text-base lg:text-lg shadow-lg p-6 rounded-2xl dark:shadow-gray-700">
          <h2 className="text-2xl font-bold  pb-4 ">Passport Status: </h2>

          <div className="grid grid-cols-2 pb-2">
            <h2 className="font-semibold">Status :</h2>
            <h2 className="text-start capitalize break-words">
              {passportStat}
            </h2>
          </div>
          {passportStat === "Submitted" ? (
            <>
              <div className="grid grid-cols-2 pb-2">
                <h2 className="font-semibold">Submission Date :</h2>
                <h2 className="text-start capitalize break-words">
                  {SubmitDate}
                </h2>
              </div>
              <div className="grid grid-cols-2 pb-2">
                <h2 className="font-semibold">Submitted By :</h2>
                <h2 className="text-start capitalize break-words">
                  {SubmittedTo}
                </h2>
              </div>
            </>
          ) : (
            <></>
          )}
          {/* Not Submitted */}
          {passportStat === "Returned" ? (
            <>
              <div className="grid grid-cols-2 pb-2">
                <h2 className="font-semibold">Return Date :</h2>
                <h2 className="text-start capitalize break-words">
                  {returnDate}
                </h2>
              </div>
              <div className="grid grid-cols-2 pb-2">
                <h2 className="font-semibold">Returned To :</h2>
                <h2 className="text-start capitalize break-words">
                  {returnedTo}
                </h2>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default PassportDetails;
