import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import { userRoleImage } from "../../../utility/Constant";

import {
  navBarSectionClass,
  navBarBodyStyledClass,
  navBarLogBoxClass,
  navBarLogoStyledClass,
  navBarBodyClass,
  navBarDropDownClass,
  navBarUserLogoClass,
  navbarUserImageClass,
  navbarMenuClass,
  toggleSwitchCheckbox,
  toggleSwitch,
  toggleBollClass,
  statusContainerClass,
  statusContainerSpan,
  statusContainerSpanInside,
} from "./styledClass";

const NavBar = ({ user, darkMode, setDarkMode, setFilter }) => {
  const { role } = user;
  const navigate = useNavigate();

  useEffect(() => {
    const isDarkMode = localStorage.getItem("darkMode") === "true";
    setDarkMode(isDarkMode);
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.remove("light");
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
      document.documentElement.classList.add("light");
    }
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const handleSwitch = () => {
    setDarkMode(!darkMode);
  };
  const userMenuList = (
    <>
      <li className="customWidth customPadding">
        <input
          type="checkbox"
          checked={darkMode}
          className={toggleSwitchCheckbox}
          id="checkbox"
          onChange={handleSwitch}
        />
        <label htmlFor="checkbox" defaultChecked className={toggleSwitch}>
          <i className="fas fa-moon" />
          <i className="fas fa-sun" />
          <div className={toggleBollClass} />
        </label>
      </li>
      <li className="customWidth">
        <button className="userBtn cursor-default">{user.name}</button>
      </li>
      {role === "admin" && (
        <li className="customWidth">
          <Link to="/admin">Manage Users</Link>
        </li>
      )}
      {(role === "admin" || role === "moderator") && (
        <li className="customWidth">
          <Link to="/stats">Statistics</Link>
        </li>
      )}
      {/* {role === "admin" && ( */}
      <li className="customWidth">
        <Link to="/documentation">Documentation</Link>
      </li>
      {/* )} */}
      {role === "admin" && (
        <li className="customWidth">
          <a
            href={`${process.env.REACT_APP_API_URL}/download`}
            className="userBtn"
          >
            Download Data
          </a>
        </li>
      )}
      <li className="customWidth">
        <button className="logoutBtn" type="button" onClick={handleLogout}>
          Logout
        </button>
      </li>
    </>
  );

  return (
    <div className={navBarSectionClass}>
      <div className={navBarBodyStyledClass}>
        <Link
          to="/"
          onClick={() => {
            setFilter([]);
          }}
          className={navBarLogBoxClass}
        >
          <img src={logo} className={navBarLogoStyledClass} alt="brandLogo" />
        </Link>

        <div className={navBarBodyClass}>
          <div className={navBarDropDownClass}>
            <label tabIndex={0} className={navBarUserLogoClass}>
              <div className={navbarUserImageClass}>
                <span className={statusContainerClass}>
                  <span className={statusContainerSpan(user.status)}></span>
                  <span
                    className={statusContainerSpanInside(user.status)}
                  ></span>
                </span>
                <img src={userRoleImage(user.role)} alt="userPhoto" />
              </div>
            </label>

            <ul tabIndex={0} className={navbarMenuClass}>
              {userMenuList}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
