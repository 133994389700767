import React from "react";

const HeadInformation = ({ employee, notViewer }) => {
  const {
    name,
    levels,
    country,
    city,
    workCategory,
    dynamicId,
    contact,
    address,
  } = employee;

  return (
    <div className="dark:text-white bg-white shadow lg:bg-transparent lg:shadow-none lg:p-0 p-6 rounded-2xl break-words h-full flex flex-col justify-between">
      <div>
        <div className="flex flex-col lg:items-start gap-2 lg:gap-0">
          <h2 className="text-xl lg:text-2xl capitalize font-bold break-words">
            {name}
          </h2>
          {/* was here */}
        </div>
        <h2 className="text-base lg:text-lg font-medium pt-2 text-gray-500 dark:text-gray-100 break-words">
          {workCategory}
          <span className="pl-1 text-base lg:text-lg font-medium text-gray-500 dark:text-gray-100 break-words">
            ({dynamicId})
          </span>
        </h2>
        <h2 className="text-base lg:text-lg font-medium pb-0 text-gray-500 dark:text-gray-100 break-words">
          {levels}
        </h2>
      </div>

      <div className="flex flex-col gap-y-2 lg:gap-y-2 pt-8">
        {notViewer && (
          <div className="flex items-center gap-3 text-base">
            <i className="fa-solid fa-phone" />
            <span className="text-base lg:text-lg break-words ml-1">
              <a href={`tel:${contact}`}> +{contact}</a>
            </span>
          </div>
        )}

        {notViewer && employee.email && (
          <div className="flex items-center gap-3 text-base lg:text-xl">
            <i className="fa-solid fa-envelope" />
            <span className="text-base lg:text-lg break-words">
              {employee.email}
            </span>
          </div>
        )}

        <div className="flex items-center gap-3 text-xl">
          <i className="fa-solid fa-map-location-dot " />
          <span className="text-base lg:text-lg capitalize break-words max-w-sm -ml-0.5">
            {address}
          </span>
        </div>

        <div className="flex items-center gap-2 text-xl">
          <div className="mr-2">
            {/* text-green-500 lg:mt-2 font-medium */}
            <i className="fa-solid fa-location-dot" />
          </div>
          <span className="text-base lg:text-lg capitalize break-words">
            {country}, {city}
          </span>
        </div>
      </div>
    </div>
  );
};

export default HeadInformation;
