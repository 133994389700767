export const inputToggleCX = "modal-toggle";

export const addButton =
  "addButton bg-[#4ADE80] border-[#4ADE80] dark:hover:border-white dark:hover:bg-white hover:text-[#4ADE80] hover:bg-[#4ADE80] ";
export const modalCX = "modal";

export const iconBodyCX = "modal-box dark:bg-[#2A303C]";

export const iconBoxCX = "text-center text-8xl text-yellow-500 pb-4";

export const iconCX =
  "fa-solid fa-exclamation border-4 border-yellow-500 rounded-full  p-4 px-14";

export const titleCX = "font-semibold text-2xl text-center dark:text-white";

export const subTitleCX = "py-4 text-center dark:text-white";

export const actionBoxCX = "flex justify-center gap-4 pt-5";

export const yesButtonCX = "btn btn-warning";

export const noButtonCX = "btn btn-error";
