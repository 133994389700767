import "./style.css";

export const modalToggleCX = "modal-toggle";

export const inputCX = "customInput dark:bg-[#2C2E33] dark:text-white";

export const modalCX = "modal";

export const modalBoxCX = "modal-box w-11/12 max-w-7xl dark:bg-[#2C2E33] ";

export const modalCloseButtonCX =
  "btn btn-sm btn-circle absolute right-2 top-2 dark:bg-white dark:text-[#303640]";

export const formBodyCX = "py-6 px-0lg:p-10";

export const formTitleCX =
  "text-3xl font-bold text-center pt-5 pb-10 dark:text-white";

export const categorySectionCX = "pb-5";

export const categoryTitleCX = "font-bold text-lg py-4 dark:text-white";

export const sectionGridCX =
  "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4";

export const profileImageCX = "flex items-center gap-4";

export const imageUploadDiv = "flex items-center gap-4";

export const imageUploadBox = "imageUploadBox ";

export const file = "file";

export const layer = (image) => {
  return image ? "layerZero" : "layer";
};

export const iconsStyle = "w-[40px] h-[40px]";

export const uploadLogoText = "font-bold dark:text-white";

export const fileNameCX = "font-semibold text-gray-600 dark:text-gray-200";

export const submitButtonCX = "flex justify-center mt-10";

export const submitButton =
  "btn btn-outline btn-wide dark:text-white dark:hover:bg-white dark:hover:text-[#2C2E33]";

export const btnContainer = "flex justify-between items-center mt-2";
export const expDelBtn = "btn btn-sm btn-circle  dark:text-[#303640]";
