import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";
import DatePic from "../datePicker";

const PassportInformation = ({ passStat, setPassStat }) => {
  const handleClearance = (event) => {
    setPassStat(event.target.value.split(",")[0]);
  };
  return (
    <div>
      <div>
        <div className={sectionGridCX}>
          <CustomInputField
            label={"Status"}
            type={"text"}
            name={"passportStat"}
            select={true}
            options={[
              ["Not Submitted", "Not Submitted"],
              ["Submitted", "Submitted"],
              ["Returned", "Returned"],
            ]}
            handleInput={handleClearance}
          />

          {passStat === "Submitted" && (
            <>
              {/*   <CustomInputField
                label={"Submission Date"}
                type={"text"}
                name={"SubmitDate"}
              /> */}
              <DatePic label={"Submission Date"} name={"SubmitDate"} />

              <CustomInputField
                label={"Submitted By"}
                type={"text"}
                name={"SubmittedTo"}
              />
            </>
          )}
          {passStat === "Returned" && (
            <>
              {/* <CustomInputField
                label={"Return Date"}
                type={"text"}
                name={"returnDate"}
              /> */}
              <DatePic label={"Return Date"} name={"returnDate"} />

              <CustomInputField
                label={"Returned To"}
                type={"text"}
                name={"returnedTo"}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PassportInformation;
