import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";
import DatePic from "../datePicker";

const PersonalInformation = () => {
  return (
    <div className={sectionGridCX}>
      <CustomInputField
        label={"Name"}
        type={"text"}
        name={"name"}
        required={true}
      />

      <DatePic label={"Date of Birth"} required={true} name={"dob"} />
      {/* <CustomInputField
        label={"Date of Birth"}
        type={"date"}
        name={"dob"}
        required={true}
      /> */}

      <CustomInputField
        label={"Gender"}
        type={"text"}
        name={"gender"}
        select={true}
        options={[
          ["Male", "Male"],
          ["Female", "Female"],
          ["Transgender", "Transgender"],
          ["Prefer not to respond", "Prefer not to respond"],
        ]}
        required={true}
      />

      <CustomInputField
        label={"Marital Status"}
        type={"text"}
        name={"maritalStatus"}
        select={true}
        options={[
          ["Single", "Single"],
          ["Married", "Married"],
          ["Divorced", "Divorced"],
          ["Separated", "Separated"],
          ["Widowed", "Widowed"],
        ]}
        required={true}
      />

      <CustomInputField
        label={"Disability"}
        type={"text"}
        name={"disability"}
        select={true}
        options={[
          ["No", "No"],
          ["Yes", "Yes"],
          ["Prefer not to respond", "Prefer not to respond"],
        ]}
        required={true}
      />

      <CustomInputField
        label={"Phone Number"}
        type={"number"}
        name={"contact"}
        required={true}
      />

      <CustomInputField label={"Email"} type={"email"} name={"email"} />
      <CustomInputField
        label={"Driving License Number"}
        type={"text"}
        name={"drivingLicenseNo"}
      />
      <CustomInputField
        label={"Country"}
        type={"text"}
        name={"country"}
        required={true}
      />

      <CustomInputField
        label={"City"}
        type={"text"}
        name={"city"}
        required={true}
      />

      <CustomInputField
        label={"Address"}
        type={"text"}
        name={"address"}
        spanFull={true}
        paragraph={true}
        required={true}
      />
    </div>
  );
};

export default PersonalInformation;
