import React from "react";
import ImageShow from "./ImageShow";

const minStringLength = 2;

const Gallery = ({ media }) => {
  return (
    <div>
      <h2 className="text-2xl font-bold p6 dark:text-white">Media</h2>
      <div className="grid grid-cols1-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 pt-6 px-4">
        {media[0].length > minStringLength ? (
          media.map((item, index) => (
            <ImageShow key={index} index={index} image={item} />
          ))
        ) : (
          <p className="text-lg">No media available</p>
        )}
      </div>
    </div>
  );
};

export default Gallery;
