import React from "react";
import {
  searchBarBodyStyle,
  searchBarContainerStyle,
  searchBarIconStyle,
  searchBarInputStyle,
} from "./styledClass";

const SearchBar = ({ searchBarActive, handleSearch, status }) => {
  return (
    <div className={searchBarContainerStyle}>
      <div className={searchBarBodyStyle}>
        <input
          onChange={handleSearch}
          type="text"
          name="search"
          placeholder={status === "inactive" ? "User Inactive" : "Search..."}
          className={searchBarInputStyle(searchBarActive)}
          disabled={status === "inactive"}
        />
        <label href="#" className={searchBarIconStyle}>
          <i className="fas fa-search"></i>
        </label>
      </div>
    </div>
  );
};

export default SearchBar;
