import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";
import DatePic from "../datePicker";

const CitizenshipInformation = () => {
  return (
    <div>
      <div className={sectionGridCX}>
        <CustomInputField
          label={"Nationality"}
          type={"text"}
          name={"nationality"}
          required={true}
        />

        <CustomInputField label={"NID Number"} type={"text"} name={"nidNo"} />

        <CustomInputField
          label={"Passport Number"}
          type={"text"}
          name={"passportNo"}
          uppercase={true}
        />

        {/* <CustomInputField
          label={"Passport Expire Date"}
          type={"date"}
          name={"passportExpDate"}
        /> */}
        <DatePic label={"Passport Expire Date"} name={"passportExpDate"} />
      </div>
    </div>
  );
};

export default CitizenshipInformation;
