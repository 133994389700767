import React from "react";
import { inputContainerCX, inputCX, legendCx } from "./styledClass";

const CustomInputFieldForWork = ({
  label,
  type,
  name,
  defaultValue,
  spanFull,
  paragraph,
  select,
  options,
  required,
  handleInput,
}) => {
  let updatedOptions = [];
  if (select && defaultValue) {
    updatedOptions = options.filter((item) => item[0] !== defaultValue[0]);
  } else {
    updatedOptions = options;
  }

  return (
    <fieldset className={inputContainerCX(spanFull)}>
      <legend className={legendCx}>
        <div className="flex  gap-2">
          <div>{label}</div>
          <div className="scale-[2] mt-[1px] text-red-500">
            {required && "*"}
          </div>
        </div>
      </legend>
      {select ? (
        <select
          className={inputCX + " capitalize"}
          name={name}
          defaultValue={defaultValue}
          onChange={handleInput}
        >
          {defaultValue && (
            <option value={defaultValue}>{defaultValue[1]}</option>
          )}
          {updatedOptions.map((item, index) => (
            <option key={index} value={[item[0], item[1]]}>
              {item[1]}
            </option>
          ))}
        </select>
      ) : paragraph ? (
        <textarea
          type={type}
          name={name}
          defaultValue={defaultValue}
          rows="4"
          cols="50"
          className={inputCX}
          required={required}
        />
      ) : (
        <input
          type={type}
          name={name}
          defaultValue={defaultValue ? defaultValue : ""}
          className={inputCX}
          required={required}
        />
      )}
    </fieldset>
  );
};

export default CustomInputFieldForWork;
