import React from "react";
import {
  serialThStyle,
  tableActionHeadStyle,
  tableHeadStyle,
} from "./styledClass";

const TableHead = () => {
  return (
    <thead className={tableHeadStyle}>
      <tr>
        <th className={serialThStyle}>serial No</th>
        <th>Name</th>
        <th>Work Category</th>
        {/* <th>Phone Number</th> */}
        {/* <th>Status</th> */}
        <th>Police clearance</th>
        <th>Passport</th>
        <th className={tableActionHeadStyle}>Action</th>
      </tr>
    </thead>
  );
};

export default TableHead;
