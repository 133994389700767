import React from "react";
import {
  serialThStyle,
  tableActionHeadStyle,
  tableBodyStyle,
  tableCellStyle,
  tableHeadStyle,
} from "./styledClass";

const FileView = ({ files }) => {
  return (
    <div>
      <div className="overflow-x-auto">
        <table className={tableBodyStyle}>
          <thead className={tableHeadStyle}>
            <tr>
              <th className={serialThStyle}>serial No</th>
              <th>File Name</th>
              <th>File Format</th>
              <th className={tableActionHeadStyle}>File Download Link</th>
            </tr>
          </thead>
          <tbody>
            {files.map((item, index) => (
              <tr key={index} className={tableCellStyle(index % 2 === 1)}>
                <th>{index + 1}</th>
                <td>{item.split("file/")[1]}</td>
                <td className="uppercase">
                  {item.split(".")[item.split(".").length - 1]}
                </td>
                <td>
                  <a
                    className="hover:text-blue-500"
                    rel="noreferrer"
                    target="_blank"
                    href={`${item}`}
                  >
                    Download
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FileView;
