import React, { useState } from "react";
import CustomAlert from "../customeAlert";
import UpdateEmployee from "../formModalUpdate";

import { tableBodyStyle } from "./styledClass";
import TableElement from "./TableElement";
import TableHead from "./TableHead";
import LoadingSmall from "../shared/loading/LoadingSmall";

const Table = ({
  employee,
  handleUpdateEmployee,
  updateEmployeeID,
  adminRole,
  userStatus,
}) => {
  const [deleteID, setDeleteID] = useState("");
  const [delEmployee, setDelEmployee] = useState("");
  let uniqueEmployee = "";
  if (updateEmployeeID) {
    uniqueEmployee = employee.filter((item) => item._id === updateEmployeeID);
  }

  /* const handleDeleteID = (event) => {
    setDeleteID(event.target.id);
  }; */
  const handleDeleteID = (id, name) => {
    setDelEmployee(name);
    setDeleteID(id);
  };
  return (
    <table className={tableBodyStyle}>
      <TableHead />
      <tbody>
        {employee.map((item, index) => (
          <TableElement
            key={index}
            serial={index + 1}
            item={item}
            even={index % 2 === 1}
            handleDeleteID={handleDeleteID}
            handleUpdateEmployee={handleUpdateEmployee}
            adminRole={adminRole}
            userStatus={userStatus}
          />
        ))}
      </tbody>
      {deleteID && (
        <CustomAlert
          endPoint="form"
          deleteID={deleteID}
          userName={delEmployee}
        />
      )}
      {updateEmployeeID && <UpdateEmployee uniqueEmployee={uniqueEmployee} />}
    </table>
  );
};

export default Table;
