import "./style.css";
export const searchBarContainerStyle = "my-10 flex justify-center";

export const searchBarBodyStyle =
  "min-w-[200px] search-container bg-[white] dark:bg-[#292929] whiteBox dark:blackBox ";

export const searchBarInputStyle = (active) => {
  return active
    ? "search-input w-[400px!important] dark:text-white"
    : "search-input dark:text-white";
};

export const searchBarIconStyle = "search-btn";
