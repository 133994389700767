import "./style.css";
export const navBarSectionClass = " p-4 bg-[#DD0505] sticky top-0 z-50 ";

export const navBarStyledClass = "navbar container mx-auto ";

export const navBarBodyStyledClass =
  "container flex flex-wrap items-center justify-between mx-auto";

export const navBarLogBoxClass = "flex px-4 ";

export const navBarLogoStyledClass = "flex items-center w-[140px]";

export const navBarBodyClass = "flex-none";

export const navBarDropDownClass = "dropdown dropdown-end";

export const navBarUserLogoClass = "btn btn-ghost btn-circle avatar";

export const navbarUserImageClass = "w-10 rounded-full";
export const statusContainerClass =
  "flex absolute h-3 w-3 top-0 right-0 -mt-1 mr-1";

export const statusContainerSpan = (status) => {
  if (status === "active") {
    return "animate-ping absolute inline-flex h-full w-full rounded-full bg-green-300 opacity-75";
  } else {
    return "animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-300 opacity-75";
  }
};

export const statusContainerSpanInside = (status) => {
  if (status === "active") {
    return "relative inline-flex rounded-full h-3 w-3 bg-green-400";
  } else {
    return "relative inline-flex rounded-full h-3 w-3 bg-yellow-300";
  }
};

export const navbarMenuClass =
  "px-5 pt-3 py-2.5 menu-compact dropdown-content shadow rounded-box w-52 font-semibold bg-base-100 dark:bg-[#383737] dark:text-white";

export const toggleSwitchCheckbox = "checkbox";

export const toggleSwitch = "label bg-[#383737] dark:bg-[#6e6b6b]";

export const toggleBollClass = "ball";
