import moment from "moment";
import React from "react";
import CustomInputField from "../customInputField";
import { sectionGridCX } from "./styledClass";
import DatePic from "../datePicker";

const PersonalInformation = ({ uniqueEmployee }) => {
  const {
    name,
    dob,
    gender,
    disability,
    maritalStatus,
    contact,
    email,
    country,
    city,
    drivingLicenseNo,
    address,
  } = uniqueEmployee;

  return (
    <div className={sectionGridCX}>
      <CustomInputField
        label={"Name"}
        type={"text"}
        name={"name"}
        defaultValue={name}
        required={true}
      />

      <DatePic
        label={"Date of Birth"}
        required={true}
        name={"dob"}
        defaultValue={dob}
      />
      {/* <CustomInputField
        label={"Date of Birth"}
        type={"date"}
        name={"dob"}
        defaultValue={moment(dob).format("YYYY-MM-DD")}
        required={true}
      /> */}

      <CustomInputField
        label={"Gender"}
        type={"text"}
        name={"gender"}
        select={true}
        options={[
          ["Male", "Male"],
          ["Female", "Female"],
          ["Transgender", "Transgender"],
          ["Prefer not to respond", "Prefer not to respond"],
        ]}
        defaultValue={gender}
        required={true}
      />

      <CustomInputField
        label={"Marital Status"}
        type={"text"}
        name={"maritalStatus"}
        select={true}
        options={[
          ["Single", "Single"],
          ["Married", "Married"],
          ["Divorced", "Divorced"],
          ["Separated", "Separated"],
          ["Widowed", "Widowed"],
        ]}
        defaultValue={maritalStatus}
        required={true}
      />

      <CustomInputField
        label={"Disability"}
        type={"text"}
        name={"disability"}
        select={true}
        options={[
          ["No", "No"],
          ["Yes", "Yes"],
          ["Prefer not to respond", "Prefer not to respond"],
        ]}
        defaultValue={disability}
        required={true}
      />

      <CustomInputField
        label={"Phone Number"}
        type={"number"}
        name={"contact"}
        defaultValue={contact}
        required={true}
      />

      <CustomInputField
        label={"Email"}
        type={"email"}
        name={"email"}
        defaultValue={email}
      />
      <CustomInputField
        label={"Driving License Number"}
        type={"text"}
        name={"drivingLicenseNo"}
        defaultValue={drivingLicenseNo}
      />
      <CustomInputField
        label={"Country"}
        type={"text"}
        name={"country"}
        defaultValue={country}
        required={true}
      />

      <CustomInputField
        label={"City"}
        type={"text"}
        name={"city"}
        defaultValue={city}
        required={true}
      />

      <CustomInputField
        label={"Address"}
        type={"text"}
        name={"address"}
        spanFull={true}
        paragraph={true}
        defaultValue={address}
        required={true}
      />
    </div>
  );
};

export default PersonalInformation;
