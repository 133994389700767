import moment from "moment";
import React from "react";

const PersonalDetail = ({ employee }) => {
  const { dob, gender, maritalStatus, disability } = employee;
  return (
    <div className=" text-base lg:text-lg shadow-lg p-6 rounded-2xl dark:shadow-gray-700">
      <h2 className="text-2xl font-bold pb-4">Personal Details: </h2>
      <div className="grid grid-cols-2 pb-2">
        <h2 className="font-semibold">Date of Birth :</h2>
        <h2 className="text-start">{moment(dob).format("MM-DD-YYYY")}</h2>
      </div>

      <div className="grid grid-cols-2 pb-2">
        <h2 className="font-semibold">Gender:</h2>
        <h2 className="text-start capitalize">{gender.split(",")[0]}</h2>
      </div>

      <div className="grid grid-cols-2 pb-2">
        <h2 className="font-semibold">Marital Status:</h2>
        <h2 className="text-start">{maritalStatus.split(",")[0]}</h2>
      </div>

      <div className="grid grid-cols-2 pb-2">
        <h2 className="font-semibold">Disability:</h2>
        <h2 className="text-start">{disability.split(",")[0]}</h2>
      </div>
    </div>
  );
};

export default PersonalDetail;
