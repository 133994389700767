import React, { useRef } from "react";
import { updateData } from "../../hooks/update";
import CustomInputField from "../customInputField";

import {
  sectionGridCX,
  formBodyCX,
  formTitleCX,
  modalBoxCX,
  modalCloseButtonCX,
  modalCX,
  modalToggleCX,
  submitButtonCX,
  submitButton,
} from "./styledClass";

const AddUser = () => {
  const formRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      name: event.target.name?.value,
      email: event.target.email?.value + "@banglahaat.com",
      password: event.target.password?.value,
      confirmPassword: event.target.password?.value,
      role: event.target.role?.value.split(",")[0],
      status: event.target.status?.value.split(",")[0],
    };
    updateData({
      endPoint: "user/create",
      data: data,
      method: "POST",
      modalCloseID: "addUser",
    });
  };
  return (
    <div>
      <input type="checkbox" id="addUser" className={modalToggleCX} />
      <div className={modalCX}>
        <div className={modalBoxCX}>
          <label
            htmlFor="addUser"
            className={modalCloseButtonCX}
            onClick={() => document.getElementById("addUser-form").reset()}
          >
            ✕
          </label>
          <form
            id="addUser-form"
            ref={formRef}
            onSubmit={handleSubmit}
            className={formBodyCX}
          >
            <h2 className={formTitleCX}>Add User</h2>

            <div className={sectionGridCX}>
              <CustomInputField
                label={"User Full Name"}
                type={"text"}
                name={"name"}
                required={true}
              />

              <CustomInputField
                label={"Username"}
                type={"text"}
                name={"email"}
                required={true}
              />

              <CustomInputField
                label={"User Password"}
                type={"password"}
                name={"password"}
                required={true}
              />

              <CustomInputField
                label={"User Role"}
                type={"text"}
                name={"role"}
                select={true}
                options={[
                  ["admin", "Admin"],
                  ["moderator", "Moderator"],
                  ["viewer", "Viewer"],
                  ["client", "Client"],
                  ["client-admin", "client-admin"],
                ]}
              />
              <CustomInputField
                label={"User Status"}
                type={"text"}
                name={"status"}
                select={true}
                options={[
                  ["active", "Active"],
                  ["inactive", "Inactive"],
                ]}
              />
            </div>

            <div className={submitButtonCX}>
              <button type="submit" className={submitButton}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
