import React from "react";
import logo from "../../../assets/logo.svg";
import logoColor from "../../../assets/logoColor.svg";
import {
  footerBody,
  footerBox,
  footerContainer,
  footerImage,
} from "./styledClass";

const Footer = ({ darkTheme }) => {
  return (
    <footer className={footerContainer}>
      <div className={footerBox}>
        <div className={footerBody}>
          <img
            src={darkTheme ? logo : logoColor}
            alt="logo"
            className={footerImage}
          />
          <p>&copy; {new Date().getFullYear()} Bangla Haat Limited</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
