import React from "react";
import CustomInputField from "../customInputField";
import { categorySectionCX } from "./styledClass";

const AddEmp = ({ number, experienceDetails, setExperienceDetails }) => {
  return (
    <div id={number} className={`${categorySectionCX} space-y-3`}>
      <CustomInputField
        label={"Company Name"}
        type={"text"}
        name={"empCompanyName"}
        required={true}
      />

      <CustomInputField label={"Country"} type={"text"} name={"empCountry"} />

      <CustomInputField
        label={"Employment Date"}
        type={"text"}
        name={"employmentDate"}
      />

      <CustomInputField label={"Duration"} type={"text"} name={"empDuration"} />

      <CustomInputField
        label={"Current Location"}
        type={"text"}
        name={"empCurrentLocation"}
      />

      <CustomInputField
        label={"Remarks"}
        type={"text"}
        name={"empRemarks"}
        paragraph
        rows={2}
      />
    </div>
  );
};

export default AddEmp;
